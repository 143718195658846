import { VueConstructor } from "vue/types/umd";
import Header from '@/components/Header.vue';
import TextImage from '@/components/TextImage.vue';
import Video from '@/components/Video.vue';
import FullwidthImage from '@/components/FullwidthImage.vue';
import Workshops from '@/components/Workshops.vue';
import Contact from '@/components/Contact.vue';
import Footer from '@/components/Footer.vue';

export const ComponentMap : Map<string, VueConstructor> = new Map<string, VueConstructor>();
ComponentMap.set('Header', Header);
ComponentMap.set('TextImage', TextImage);
ComponentMap.set('Video', Video);
ComponentMap.set('FullwidthImage', FullwidthImage);
ComponentMap.set('Workshops', Workshops);
ComponentMap.set('Contact', Contact);
ComponentMap.set('Footer', Footer);
