















// @ is an alias to /src
import Article, {  TextImageArticle } from '@/types/Article';
import { VueConstructor } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class Footer extends Vue {
    @Prop() article!: TextImageArticle;
}

